module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-DSR7TVLXWJ"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"theme_color":"#6D5DF2","background_color":"#F2F2F2","display":"standalone","scope":"/","start_url":"/","name":"JSON editor","short_name":"JSONF","description":"Free online&offline JSON editor","icon":"./src/images/icon-512x512.png","icons":[{"src":"/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/icon-512x512.png","sizes":"512x512","type":"image/png"}],"shortcuts":[{"name":"JSON editor","short_name":"JSONF","description":"Free online&offline JSON editor","url":"/","icons":[{"src":"/images/icon-192x192.png","sizes":"192x192"}]},{"name":"JSON editor","short_name":"JSONF","description":"Free online&offline JSON editor","url":"/","icons":[{"src":"/images/icon-192x192.png","sizes":"192x192"}]}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c3460b1f2c702dcbe580724571218bba"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["ar","de","el","en","es","fr","hi","it","ja","ko","ms","pt","ru","zh-CHS","zh-CHT"],"defaultLanguage":"en","siteUrl":"https://jsoneditor.myxo2024.tools","i18nextOptions":{"fallbackLng":"en","supportedLngs":["ar","de","el","en","es","fr","hi","it","ja","ko","ms","pt","ru","zh-CHS","zh-CHT"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
